import { Box, Container, styled, Typography } from "@mui/material";

export const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('lg')]: {
        margin: 0,
    },

    [theme.breakpoints.up('xl')]: {
        marginTop: '8rem',
        maxWidth: '2000px'
    }
}))

export const StyledExperience = styled(Box)(({ theme }) => ({
    border: '0.1rem solid',
    padding: '2rem',
    borderRadius: '2rem',
    textAlign: 'left',
    width: '60%',
    position: 'relative',
    left: '12rem',
    marginBottom: '2rem',
    borderColor: theme.palette.textColor.borderColor,
    color: theme.palette.textColor.paragraph,

    [theme.breakpoints.up('xl')]: {
        width: `calc(70% + (100vw - 1536px) * 0.1)`,
        left: `calc(9rem + (100vw - 1536px) * 0.01)`
    },

    '@media (min-width: 2560px)': {
        width: '100%',
        left: '-2rem'
    },

    [theme.breakpoints.down('md')]: {
        left: 0,
        width: '88%',
        padding: '1rem'
    },

    [theme.breakpoints.only('md')]: {
        left: '10rem',
    },

    [theme.breakpoints.only('sm')]: {
        left: '1rem',
        width: '90%',
    },
}))

export const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    fontWeight: 600,
    textAlign: 'left',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(2rem + (100vw - 1536px) * 0.01)`,
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        textAlign: 'center',

    }
}))

export const StyledLocation = styled(Typography)(({ theme }) => ({
    fontStyle: "italic",
    fontWeight: 600,
    fontSize: '1rem',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`
    }
}))

export const StyledDate = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    backgroundColor: '#3535351a',
    width: 'fit-content',
    padding: '0 0.5rem',
    marginTop: '.5rem',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1rem + (100vw - 1536px) * 0.01)`,
    },

}))

export const StyledList = styled(Typography)(({ theme }) => ({
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '.2rem',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.5rem + (100vw - 1920px) * 0.01)`,
        marginBottom: '.5rem',

    },
}))

export const StyledIcon = styled('img')(({ theme }) => ({
    height: '2rem',

    [theme.breakpoints.up('xl')]: {
        height: `calc(3rem + (100vw - 1920px) * 0.01)`,
    },
}))