import { Box, styled } from "@mui/material";
import { StyledAbout } from "../About/styled";


export const StyledContact = styled(StyledAbout)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    margin: '2rem 0',
    backgroundColor: 'rgb(250, 250, 250)',

    [theme.breakpoints.up('xl')]: {
        borderRadius: '4rem'
    },

    [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: '1rem 0',
        padding: '.5rem 1rem'
    }
}))

export const StyledContactInfo = styled(Box)(() => ({
    display: 'flex', 
    alignItems: 'center', 
    gap: '.2rem'
}))