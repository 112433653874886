import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        textColor: {
            main: '#000',
            paragraph:'rgb(85, 85, 85)',
            borderColor: 'rgb(163, 163, 163)',
            whiteColor: '#fff',
            buttonColor: 'rgb(53, 53, 53)'
        },
    },

    typography: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 500,
        color: '#000'
    },
})

export default theme
