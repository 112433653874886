import React from 'react'
import {
    StyledBox,
    StyledButton,
    StyledButtonContainer,
    StyledContainer,
    StyledIcon,
    StyledImg,
    StyledImgContainer,
    StyledLink,
    StyledLinkContainer,
    StyledText1,
    StyledText2,
    StyledText3,
} from './styled'
import { useJsonValues } from '../../hooks'
import { Link } from 'react-router-dom'

const Profile = () => {
    const { data } = useJsonValues()

    const {
        image,
        greeting,
        name,
        work,
        downloadCV,
        contactInfo,
        blogLink,
        links = [],
    } = data.profile

    return (
        <StyledContainer id="profile">
            <StyledImgContainer>
                <StyledImg src={image} alt="Samaila Chatto Bashir" />
            </StyledImgContainer>

            <StyledBox>
                <StyledText1>{greeting}</StyledText1>

                <StyledText2>{name}</StyledText2>

                <StyledText3>{work}</StyledText3>

                <StyledButtonContainer>
                    <StyledButton
                        variant="outlined"
                        onClick={() =>
                            window.open('/assets/chatto-cv.pdf', '_blank')
                        }
                    >
                        {downloadCV}
                    </StyledButton>

                    <StyledButton variant="contained">
                        <StyledLink to={blogLink} target="_blank">
                            {contactInfo}
                        </StyledLink>
                    </StyledButton>
                </StyledButtonContainer>

                <StyledLinkContainer>
                    {links.map(({ link, image }, index) => (
                        <Link to={link} target="_blank" key={index}>
                            <StyledIcon src={image} alt="" height="30px" />
                        </Link>
                    ))}
                </StyledLinkContainer>
            </StyledBox>
        </StyledContainer>
    )
}

export default Profile
