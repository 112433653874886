import { Box, Typography } from '@mui/material'
import React from 'react'
import { StyledText2 } from '../Profile/styled'

const SubHeadings = ({ text, title }) => {
    return (
        <Box textAlign={'center'}>
            <Typography
                sx={{
                    fontSize: {
                        xl: `calc(1.3rem + (100vw - 1536px) * 0.01)`,
                        xs: '1rem',
                    },
                    color: 'textColor.paragraph',
                }}
            >
                {text}
            </Typography>
            <StyledText2>{title}</StyledText2>
        </Box>
    )
}

export default SubHeadings
