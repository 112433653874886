import { Box, Container } from '@mui/material'
import React from 'react'
import SubHeadings from '../SubHeadings'
import {
    StyledContainer,
    StyledDate,
    StyledExperience,
    StyledIcon,
    StyledList,
    StyledLocation,
    StyledText,
} from './styled'
import { useJsonValues } from '../../hooks'

const Experience = () => {
    const { data } = useJsonValues()

    const { text, title, experienceContents = [] } = data.experience

    return (
        <StyledContainer id="experience">
            <Container sx={{ mt: '2rem' }} disableGutters>
                <SubHeadings text={text} title={title} />

                <Box sx={{ mt: { sm: '1rem', xs: '.5rem' } }}>
                    {experienceContents.map(
                        ({ experienceTitle, location, date, lists }, index) => (
                            <StyledExperience key={index}>
                                <StyledText>{experienceTitle}</StyledText>
                                <StyledLocation>{location}</StyledLocation>
                                <StyledDate>{date}</StyledDate>

                                <Box sx={{ mt: '1rem' }}>
                                    {lists.map(({ image, list }, index) => (
                                        <StyledList key={index}>
                                            <StyledIcon
                                                src={image}
                                                alt="check"
                                            />
                                            {list}
                                        </StyledList>
                                    ))}
                                </Box>
                            </StyledExperience>
                        )
                    )}
                </Box>
            </Container>
        </StyledContainer>
    )
}

export default Experience
