import { styled } from "@mui/material";
import { Link } from "react-router-dom";


export const StyledLink = styled(Link)(({theme}) => ({
    textDecoration: 'none',
    color: 'black',
    transition: 'all 300ms ease',

    '&: hover': {
        color: 'grey',
        textDecoration: 'underline',
        textUnderlineOffset: '1rem',
        textDecorationColor: 'rgb(181, 181, 181)',
    },

    [theme.breakpoints.down('md')]: {
        padding: '1rem'
    }
}))