import {
    Box,
    Container,
    Typography,
} from '@mui/material'
import React from 'react'
import Menu from '../Header/Menu'
import { StyledLink } from '../Header/Menu/styled'

const Footer = () => {

    const menuFontSize = {
        xs: '1rem',
        md: '1.5rem',
        xl: `calc(2rem + (80vw - 1536px) * 0.02)`,
    }

    return (
        <Container disableGutters>
            <Box
                sx={{
                    display: { sm: 'flex' },
                    gap: { xl: '3rem', md: '2rem', sm: '1rem' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: {sm: 0, xs: '1rem'}
                }}
            >
                <Menu fontSize={menuFontSize} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: '2rem',
                    textAlign: 'center',
                }}
            >
                <Typography
                    color={'textColor.paragraph'}
                    sx={{
                        fontSize: {
                            xl: `calc(1.5rem + (100vw - 1536px) * 0.01)`,
                            xs: '1rem',
                        },
                    }}
                >
                    Copyright © 2024{' '}
                    <StyledLink sx={{ padding: '0 !important' }}>
                        Samaila Chatto Bashir
                    </StyledLink>
                    . All Rights Reserved.
                </Typography>
            </Box>
        </Container>
    )
}

export default Footer
