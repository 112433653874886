import { Box, Typography } from '@mui/material'
import React from 'react'
import SubHeadings from '../SubHeadings'
import { StyledContainer } from '../Experience/styled'
import { StyledContact, StyledContactInfo } from './styled'
import { StyledLink } from '../Header/Menu/styled'
import { useJsonValues } from '../../hooks'
import { StyledIcon } from '../About/styled'

const Contact = () => {
    const { data } = useJsonValues()

    const { text, title, contactInfo = [] } = data.contact

    return (
        <StyledContainer id="contact">
            <Box sx={{ mt: {lg: '1rem', md: '2rem'} }}>
                <SubHeadings text={text} title={title} />

                <StyledContact>
                    {contactInfo.map(
                        ({ icon, alt, contactTitle, link }, index) => (
                            <StyledContactInfo key={index}>
                                <StyledIcon src={icon} alt={alt} />
                                <StyledLink to={link}>
                                    <Typography
                                        variant="h6"
                                        fontWeight={400}
                                        sx={{
                                            fontSize: {
                                                xl: `calc(1.5rem + (100vw - 1536px) * 0.01)`,
                                                sm: '1.25rem',
                                                xs: '1rem',
                                            },
                                        }}
                                    >
                                        {contactTitle}
                                    </Typography>
                                </StyledLink>
                            </StyledContactInfo>
                        )
                    )}
                </StyledContact>
            </Box>
        </StyledContainer>
    )
}

export default Contact
